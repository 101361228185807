<template>
  <div>
    <div class="flex-container">
      <div class="b">
        <div v-if="mode === 0">
          <h1 class="title1">ile<span style="font-weight: 600; color: #ff4700">Zycia</span>Procent</h1>
          <h2 class="title2">Ile statystycznie zostało Twojego życia?</h2>
          <br>
          <p class="smoll">Ustaw datę urodzenia</p>
          <b-datepicker
              v-model="date"
              :show-week-number="showWeekNumber"
              :locale="locale"
              placeholder="Ustaw datę urodzenia"
              icon="calendar"
              :icon-right="date ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDate"
              trap-focus>
          </b-datepicker>
          <p class="smoll">Wybierz płeć</p>
          <div class="block" style="margin-bottom: 0.5rem;">
            <b-radio v-model="sex"
                     name="name"
                     native-value="m">
              Mężczyzna
            </b-radio>
            <b-radio v-model="sex"
                     name="name"
                     native-value="k">
              Kobieta
            </b-radio>
          </div>
          <div class="buttons is-centered">
            <b-button type="is-info" @click="mode = 1; calculate()">Sprawdź</b-button>
          </div>
        </div>
        <div v-if="mode === 1">
          <h1 class="title1">ileProcent<span style="font-weight: 600; color: #ff4700">Zycia</span>.pl</h1>
          <h2 class="title2" v-if="trueData"><u>Statystycznie</u> Twojego życia mineło <span style="font-weight: bold">{{ percent.toFixed(2) }}%</span>, czyli zostało {{ (100-percent).toFixed(2) }}% Twojego życia.</h2>
          <h2 class="title2" v-else>A co jeśli mineło {{ percent.toFixed(2) }}% Twojego życia</h2>
          <br>
          <b-progress :value="percent" type="is-danger" format="percent" show-value></b-progress>
          <h2 class="title3">1% Twojego statystycznego życia mija w ciągu {{ Math.round((year/100)*365) }} dni, a 0.1% w ciągu {{ Math.round((0.1*year/100)*365) }} dni </h2>
          <br>
          <h2 class="title3">Popatrz na to z drugiej strony, na czas Twojego życia, który już minął. Ile go zmarnowałeś/aś? Zielona sekcja to czas, który mogłeś/aś wykorzystać, czarna sekcja to sen.</h2>
          <br>
          <b-progress format="percent">
            <template #bar>
              <b-progress-bar :value="((1/3)*percent).toFixed(2)" show-value>{{ ((1/3)*percent).toFixed(2) }}% SEN</b-progress-bar>
              <b-progress-bar type="is-success" :value="((2/3)*percent).toFixed(2)" show-value>{{ ((2/3)*percent).toFixed(2) }}% CZAS</b-progress-bar>
            </template>
          </b-progress>
<!--          <h2 class="title3" v-if="truePercent < 30">Wydaje się że to mało</h2>-->
          <h2 class="title3">Tak naprawdę nie wiesz, ile czasu Twojego życia mineło, może to być <a href="#" v-on:click="percent = 99; trueData = false;">99%</a>, <a href="#" v-on:click="percent = 25; trueData = false;">25%</a>, <a href="#" v-on:click="percent = 50; trueData = false;">50%</a>, <a href="#" v-on:click="percent = 75; trueData = false;">75%</a>, możesz zyć nawet więcej niż statystycznie.</h2>
          <h2 class="title3">Strona inspirowana <a href="https://www.facebook.com/watch/?v=877310772421076" target="_blank">filmem NasDaily</a></h2>
          <h2 class="title2">Carpe diem - chwytaj dzień</h2>
        </div>
        <br>
        <p class="has-text-centered is-4">Stworzono z użyciem <a href="https://buefy.org/" target="_blank">Buefy</a>, czcionki <a href="https://fonts.google.com/specimen/Poppins" target="_blank">Poppins</a>, <a href="https://cli.vuejs.org/" target="_blank">Vue CLI</a>, <a href="https://router.vuejs.org/">vue-router</a> oraz <a href="https://fontawesome.com/" target="_blank">FontAwesome</a>. Dane ze <a href="http://stat.gov.pl/" target="_blank">stat.gov.pl</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      //1164297960000
      date: new Date(),
      selected: false,
      sex: "m",
      mode: 0,
      yearsLifes: {
        m: 72.6,
        k: 80.7
      },
      lifes: {
        m: this.toDate(72.6),
        k: this.toDate(80.7)
      },
      percent: 0.0,
      truePercent: 0.0,
      trueData: true
    }
  }, methods : {
    clearDate() {
      this.date = new Date();
    }, calculate() {
      console.log(new Date().getTime()-this.date.getTime())
      console.log("URODZENIE" + this.date.getTime())
      console.log("DATA" + new Date().getTime())
      console.log(new Date().getTime()+this.lifes.m)
      this.percent = this.percentage(new Date().getTime()-this.date.getTime(), (this.sex === "m" ? this.lifes.m : this.lifes.k));
      this.truePercent = this.percentage(new Date().getTime()-this.date.getTime(), (this.sex === "m" ? this.lifes.m : this.lifes.k));
    }, percentage(num, per) {
      return (100 * num) / per;
    }, toDate(years) {
      return 60*60*24*365.25*years*1000;
    }
  }, computed: {
    year() {
      return (this.sex === "m" ? this.yearsLifes.m : this.yearsLifes.k);
    }
  }
}
</script>

<style scoped>
.smoll {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}
.title1 {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.title2 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
}

.title3 {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 400;
}

.b {
  width: 40vw;
  /*height: 35vh;*/

  background-color: #f1f1f2;

  /*position: absolute;*/
  /*top:0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*right: 0;*/

  margin: auto;
  border-radius: 16px;

  padding: 1.25rem;
}

@media screen and (max-width: 1200px) {
  .b {
    width: 60vw;
  }
}

@media screen and (max-width: 600px) {
  .b {
    width: 90vw;
  }
}


.flex-container {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
  border: 1px solid blue;
}

/*html, body {*/
/*  height: 100vh;*/
/*}*/
body {
  margin: 0;
}
</style>
